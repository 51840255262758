import auth0 from 'auth0-js';
import JwtDecode from 'jwt-decode';
import { parsePhoneNumber } from 'libphonenumber-js';
import Routes from 'Routes/profileRoutes';
import {
  readAuthTokenCookie,
  writeAuthTokenCookie,
  getCodeFromQueryStrings,
  validateIdToken,
  deleteRefreshToken,
  getCoworkerLinkingStatusFromQueryStrings,
  getUtmCampaignParams,
  getItmCampaignParams,
  isRememberMeSelected,
  isInsideIframe
} from 'Utils/auth/authUtils';
import analytics from 'Utils/analytics/analyticsWrapper';
import crypto from 'crypto-js';

const { COUNTRY, LANGUAGE, API } = CONFIG;
const CIE_HOST = `${API.CIAM.HOST}/cim/${COUNTRY}/${LANGUAGE}/v1`;

const PROFILE_URL = `${window.location.origin}/${COUNTRY}/${LANGUAGE}/${CONFIG.APP.BASE_NAME}`;

const DASHOARD_URL = `${PROFILE_URL}${Routes.DASHBOARD}`;

const icwLinkValues = ['already-linked', 'linked'];

const REGISTRATION_LINKS = {
  regularSignUpLink: `${PROFILE_URL}${Routes.SIGNUP}`,
  familySignUpLink: `${PROFILE_URL}${Routes.SIGNUP}family`
};

const webAuth = new auth0.WebAuth({
  domain: CONFIG.API.AUTH0.DOMAIN,
  clientID: CONFIG.API.AUTH0.CLIENT_ID
});

const base64URLEncode = wordArray => {
  return crypto.enc.Base64.stringify(wordArray)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
};

const sha256 = buffer => {
  return crypto.algo.SHA256.create()
    .update(buffer)
    .finalize();
};

const getChallenge = () => {
  const verifier = base64URLEncode(crypto.lib.WordArray.random(32));
  sessionStorage.setItem(`verifier_${CONFIG.COUNTRY.toLowerCase()}`, verifier);
  return base64URLEncode(sha256(verifier));
};

const redirectToCIAMSignup = async (redirectTo, page) => {
  const { search } = window.location;
  const utmCampaignValues = getUtmCampaignParams(search);
  const itmCampaignValues = getItmCampaignParams(search);
  const codeChallenge = getChallenge();
  const payload = {
    page: page,
    redirectUri: `${PROFILE_URL}${Routes.LOGIN}`,
    responseType: 'code', // code for authorization code flow
    ui_locales: `${CONFIG.LANGUAGE}-${CONFIG.COUNTRY.toUpperCase()}`,
    codeChallenge,
    codeChallengeMethod: 'S256',
    scope: 'openid profile email offline_access',
    ...(redirectTo && {
      state: JSON.stringify(`redirectTo=${redirectTo}`)
    }),
    ...(utmCampaignValues &&
      utmCampaignValues !== '||' && {
        campaignSource: utmCampaignValues
      }),
    ...(itmCampaignValues &&
      itmCampaignValues !== '||' && {
        itmSource: itmCampaignValues
      }),
    audience: 'https://retail.api.ikea.com',
    consumer: 'OWF'
  };
  if (isInsideIframe()) {
    await analytics.ciePagePopup('signup_form', 'signup_page_popup_redirect');
    webAuth.popup.authorize(payload);
  } else {
    webAuth.authorize(payload);
  }
};

const login = async (redirectTo, bvEventId, icw) => {
  const { search } = window.location;
  const utmCampaignValues = getUtmCampaignParams(search);
  const itmCampaignValues = redirectTo?.includes('dashboard/projects')
    ? 'customer-project|slider|login'
    : getItmCampaignParams(search);
  const codeChallenge = getChallenge();
  const authorizePayload = {
    redirectUri: `${PROFILE_URL}${Routes.LOGIN}`,
    responseType: 'code', // code for authorization code flow
    ui_locales: `${CONFIG.LANGUAGE}-${CONFIG.COUNTRY.toUpperCase()}`,
    codeChallenge,
    codeChallengeMethod: 'S256',
    scope: 'openid profile email offline_access',
    ...(redirectTo && {
      state: JSON.stringify(`redirectTo=${redirectTo}`)
    }),
    ...(bvEventId && {
      state: JSON.stringify(`bveventid=${bvEventId}`)
    }),
    ...(icw && {
      state: JSON.stringify(`icw=${icw}`)
    }),
    audience: 'https://retail.api.ikea.com',
    ...(utmCampaignValues &&
      utmCampaignValues !== '||' && {
        campaignSource: utmCampaignValues
      }),
    ...(itmCampaignValues &&
      itmCampaignValues !== '||' && {
        itmSource: itmCampaignValues
      }),
    registration: JSON.stringify({
      from: redirectTo,
      bveventid: bvEventId,
      ...((CONFIG.ENV === 'development' || CONFIG.ENV === 'revamp') &&
        REGISTRATION_LINKS)
    }),
    consumer: 'OWF'
  };
  if (isInsideIframe()) {
    await analytics.ciePagePopup('login_form', 'login_page_popup_redirect');
    webAuth.popup.authorize(authorizePayload);
  } else {
    webAuth.authorize(authorizePayload);
  }
};

const logout = async (redirectLoggedOutTo, accessToken) => {
  const token = localStorage.getItem(
    `refreshToken_${CONFIG.COUNTRY.toLowerCase()}`
  );
  if (token) {
    await fetch(`https://${CONFIG.API.AUTH0.DOMAIN}/oauth/revoke`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        client_id: CONFIG.API.AUTH0.CLIENT_ID,
        token
      })
    });
    deleteRefreshToken();
  } else {
    try {
      const userId = JwtDecode(accessToken).sub;
      if (window.ikea && window.ikea.cie) {
        await window.ikea.cie.deleteRefreshToken({
          country: CONFIG.COUNTRY.toLowerCase(),
          lang: CONFIG.LANGUAGE.toLowerCase(),
          userId: userId,
          accessToken: accessToken
        });
      }
    } catch (error) {}
  }
  webAuth.logout({
    clientID: CONFIG.API.AUTH0.CLIENT_ID,
    returnTo: `${PROFILE_URL}${Routes.LOGOUT}?from=${redirectLoggedOutTo}`
  });
};

const getUserInfo = () => {
  return new Promise((resolve, reject) => {
    webAuth.client.userInfo(readAuthTokenCookie(), (err, user) => {
      err ? reject(err) : resolve(user);
    });
  });
};

const handleAuthentication = async queryStrings => {
  const code = getCodeFromQueryStrings(queryStrings);
  const icowLinked = getCoworkerLinkingStatusFromQueryStrings(queryStrings);
  if (!code && !icowLinked) {
    throw new Error('No exchange code received!');
  } else {
    if (code) {
      const bodyData = {
        grant_type: 'authorization_code',
        client_id: CONFIG.API.AUTH0.CLIENT_ID,
        code_verifier: sessionStorage.getItem(
          `verifier_${CONFIG.COUNTRY.toLowerCase()}`
        ),
        code,
        redirect_uri: `${PROFILE_URL}${Routes.LOGIN}`,
        scope: 'openid profile email offline_access'
      };
      const authResult = await fetchTokens(bodyData, false);
      if (authResult.refresh_token) {
        if (isRememberMeSelected(authResult.id_token)) {
          let userId = '';
          try {
            userId = JwtDecode(authResult.access_token).sub;
            if (window.ikea && window.ikea.cie) {
              await window.ikea.cie.saveRefreshToken({
                country: CONFIG.COUNTRY.toLowerCase(),
                lang: CONFIG.LANGUAGE.toLowerCase(),
                userId: userId,
                accessToken: authResult.access_token,
                refreshToken: authResult.refresh_token
              });
            }
          } catch (error) {}
        } else {
          await fetch(`https://${CONFIG.API.AUTH0.DOMAIN}/oauth/revoke`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              client_id: CONFIG.API.AUTH0.CLIENT_ID,
              token: authResult.refresh_token
            })
          });
        }
      }
      sessionStorage.removeItem(`verifier_${CONFIG.COUNTRY.toLowerCase()}`);
      return authResult;
    } else if (icwLinkValues.includes(icowLinked)) {
      return refreshToken();
    }
  }
};

const verifyPhone = (phone, partyUid) => {
  const phoneNumber = parsePhoneNumber(phone);
  const flow = CONFIG.FEATURE.ENABLE_CIAM_API ? 'PHONE_VERIFY' : 'MyProfile';
  const body = {
    callingCode: `+${phoneNumber.countryCallingCode}`,
    phoneNumber: phoneNumber.nationalNumber,
    partyUID: partyUid,
    flow
  };
  webAuth.authorize({
    redirectUri: `${DASHOARD_URL}`,
    responseType: 'token id_token',
    protocol: 'oauth2',
    max_age: 1,
    storeDir: 'no',
    ui_locales: `${CONFIG.LANGUAGE}-${CONFIG.COUNTRY.toUpperCase()}`,
    scope: 'openid profile email offline_access',
    audience: 'https://retail.api.ikea.com',
    registration: JSON.stringify({
      ...body
    }),
    consumer: 'OWF'
  });
};

const putToken = (userId, refreshToken, accessToken) => {
  fetch(`${CIE_HOST}/profile/token`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...(accessToken && {
        authorization: `Bearer ${accessToken}`
      })
    },
    body: JSON.stringify({
      token: refreshToken,
      userId
    })
  });
};

const refreshToken = async () => {
  return new Promise((resolve, reject) => {
    webAuth.checkSession(
      {
        redirectUri: `${PROFILE_URL}${Routes.LOGIN}`,
        responseType: 'token id_token',
        audience: 'https://retail.api.ikea.com',
        scope: 'openid profile email offline_access'
      },
      async function(err, authResult) {
        if (err) {
          console.log('Silent authentication error....', err);
          return reject(err);
        }
        if (
          authResult &&
          authResult.accessToken &&
          validateIdToken(authResult.idToken)
        ) {
          if (authResult.refresh_token) {
            let userId = '';
            try {
              userId = JwtDecode(authResult.accessToken).sub;
              if (window.ikea && window.ikea.cie) {
                await window.ikea.cie.saveRefreshToken({
                  country: CONFIG.COUNTRY.toLowerCase(),
                  lang: CONFIG.LANGUAGE.toLowerCase(),
                  userId: userId,
                  accessToken: authResult.accessToken,
                  refreshToken: authResult.refresh_token
                });
              }
            } catch (error) {}
          }
          await writeAuthTokenCookie(authResult.accessToken);
          return resolve(authResult);
        }
        console.log('Silent authentication Failed.');
        return reject(new Error('Invalid Token'));
      }
    );
  });
};

const fetchTokens = (bodyData, isRememberMe) => {
  return fetch(`https://${CONFIG.API.AUTH0.DOMAIN}/oauth/token`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(bodyData)
  })
    .then(response => {
      return response.json();
    })
    .then(async authResult => {
      if (
        authResult &&
        authResult.access_token &&
        validateIdToken(authResult.id_token)
      ) {
        await writeAuthTokenCookie(authResult.access_token);
        return authResult;
      } else if (isRememberMe) {
        await deleteRefreshToken();
        return;
      } else {
        throw new Error('Invalid Token!');
      }
    })
    .catch(() => {
      throw new Error('Exchange code failed!');
    });
};

export default {
  login,
  logout,
  handleAuthentication,
  redirectToCIAMSignup,
  getUserInfo,
  verifyPhone,
  refreshToken
};
