import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'Utils/localization/i18next';
import { Text, TextWithLink, InlineText } from 'Common';
import classNames from 'classnames';
import IkeaCheckboxControlled from '../_form_ikea_copies/IkeaCheckboxControlled';
import '@ingka/svg-icon/style.scss';
import '@ingka/inline-message/style.scss';
import '@ingka/forms/style.scss';
import styles from './Checkbox.scss';

const CheckboxControlled = props => {
  return (
    <React.Fragment>
      <IkeaCheckboxControlled
        id={`${props.formId}-${props.name}`}
        name={props.name}
        // Defines default state of the IkeaCheckbox.
        checked={props.value}
        // It is not possible to handle IkeaCheckbox as a "controlled"
        // input. A workaround is to use the "checked" prop and set a
        // new default state of the checkbox. Default state is only
        // considered when the component is mounting. Component remount
        // can be triggered by toggling the "key" prop.
        // key={`${props.formId}-${props.name}-${props.value}`}
        text={
          props.label ? (
            <Text>{t(props.label)}</Text>
          ) : (
            <TextWithLink
              labelBeforeLink={t(props.labelBeforeLink)}
              labelAfterLink={t(props.labelAfterLink)}
              labelLink={t(props.labelLink)}
              labelBetweenLinks={props.labelBetweenLinks}
              link={t(props.link)}
              linkTarget="_blank"
              lineBreak={props.labelLinkLineBreak}
              linkLabels={props.linkLabels}
              links={props.links}
              name={props.name}
            />
          )
        }
        prefix={CONFIG.APP.STYLES_PREFIX}
        disabled={props.disabled}
        describedById={`${props.formId}-${props.name}-error`}
        className={classNames(styles.checkbox, props.className, {
          [styles[`checkbox-margin-${props.margin}`]]:
            props.margin !== undefined,
          [styles[`checkbox-margin-top-${props.marginTop}`]]:
            props.marginTop !== undefined,
          [styles[`checkbox-margin-left-${props.marginLeft}`]]:
            props.marginLeft !== undefined,
          [styles[`checkbox-margin-right-${props.marginRight}`]]:
            props.marginRight !== undefined,
          [styles[`checkbox-margin-bottom-${props.marginBottom}`]]:
            props.marginBottom !== undefined
        })}
        onChange={props.onChange}
      />
      {props.extraTextMsg && <InlineText msg={t(props.extraTextMsg)} />}
    </React.Fragment>
  );
};

CheckboxControlled.propTypes = {
  formId: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  labelBeforeLink: PropTypes.string,
  labelBetweenLinks: PropTypes.arrayOf(PropTypes.string),
  linkLabels: PropTypes.arrayOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.string),
  labelAfterLink: PropTypes.string,
  labelLink: PropTypes.string,
  labelLinkLineBreak: PropTypes.string,
  link: PropTypes.string,
  disabled: PropTypes.bool,
  extraTextMsg: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.bool,
  margin: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', '2xl']),
  marginTop: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', '2xl']),
  marginBottom: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', '2xl']),
  marginLeft: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', '2xl']),
  marginRight: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', '2xl']),
  onChange: PropTypes.func
};

export default CheckboxControlled;
