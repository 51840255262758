export const zipCodeRegex = {
  AD: /^AD[1-9]\d{2}$/,
  AE: /^\d{5}$/,
  AL: /^[1-9]\d{3}$/,
  AT: /^[1-9]\d{3}$/,
  AU: /^\d{4}$/,
  AX: /^22\d{3}$/,
  BA: /^7\d{4}$|^8[089]\d{3}$/,
  BE: /^[1-9]\d{3}$/,
  BG: /^[1-9]\d{3}$/,
  BY: /^2\d{5}$/,
  CA: /^(?!.*[DFIOQU])[A-VXY]\d[A-Z] ?\d[A-Z]\d$/,
  CH: /^[1-9]\d{3}$/,
  CL: /^[1-9]\d{6}$/,
  CN: /^\d{6}$/,
  CO: /^\d{6}$/,
  CY: /^[1-9]\d{3}$/,
  CZ: /^\d{5}$/,
  DE: /^\d{5}$/,
  DK: /^[1-9]\d{3}$/,
  DO: /^1\d{4}$/,
  EE: /^[1-9]\d{4}$/,
  EG: /^[1-9]\d{4}$/,
  ES: /^(?!07.*|35.*|38.*)\d{5}$/,
  FI: /^\d{5}$/,
  FO: /^[1-9]\d{2}$/,
  FR: /^\d{5}$/,
  GB: /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?\d\d?)|(([a-pr-uwyzA-PR-UWYZ]\d[a-hjkstuwpA-HJKSTUWP])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]\d[abehmnprv-yABEHMNPRV-Y]))) \d[abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/,
  GE: /^\d{4}$/,
  GL: /^39\d{2}$/,
  GR: /^[1-9]\d{4}$/,
  HR: /^[1-9]\d{4}$/,
  HU: /^[1-9]\d{3}$/,
  ID: /^[1-9]\d{3}0$/,
  IE: /^[AC-FHKNPRTV-Yac-fhknprtv-y]\d[\dWw][ ][\dAC-FHKNPRTV-Yac-fhknprtv-y]{4}$/,
  IL: /^\d{7}$/,
  IN: /^\d{6}$/,
  IS: /^[1-9]\d{2}$/,
  IT: /^\d{5}$/,
  JO: /^[1-9]\d{4}$/,
  JP: /^\d{3}-\d{4}$/,
  KR: /^\d{5}$/,
  KW: /^\d{5}$/,
  KZ: /^\d{6}$/,
  LI: /^(94)((8[5-9])|(9[0-8]))$/,
  LT: /^\d{5}$/,
  LV: /^[1-9]\d{3}$/,
  MA: /^[1-9]\d{4}$/,
  MC: /^980\d{2}$/,
  MD: /^(MD-)\d{4}$/,
  ME: /^[8]\d{4}$/,
  MK: /^[1-9]\d{3}$/,
  MT: /^[a-z]{3} \d{4}$/i,
  MX: /^\d{5}$/,
  MY: /^\d{5}$/,
  NL: /^[1-9]\d{3}[A-Z]{2}$/,
  NO: /^\d{4}$/,
  NZ: /^\d{4}$/,
  OM: /^[1-9]\d{2}$/,
  PH: /^\d{4}$/,
  PL: /^\d{2}-?\d{3}$/,
  PR: /^00\d{3}$/,
  PT: /^[1-9]\d{3}-\d{3}$/,
  QA: /^\d{5}$/,
  LU: /^[1-9]\d{3}$/,
  RO: /^\d{6}$/,
  RS: /^[1-9]\d{4}$/,
  RU: /^[1-9]\d{5}$/,
  SA: /^[1-9]\d{4}$/,
  SE: /^\d{3} ?\d{2}$/,
  SG: /^\d{6}$/,
  SI: /^[1-9]\d{3}$/,
  SK: /^\d{5}$/,
  SM: /^\d{5}$/,
  TH: /^[1-9]\d{4}$/,
  TR: /^\d{5}$/,
  TW: /^[1-9]\d{4}$/,
  UA: /^\d{5}$/,
  US: /^\d{5}$/,
  default: /^[a-z\d -]{2,20}$/i
};
