export const generalRegex = {
  alphanumericDashAndSpace: /^[a-zA-Z0-9 -]*$/,
  notNumbers: /^([^0-9])*$/,
  notSpecialChars: /^([^ ¡!¿?"#$%&'()*+,-./:<=>@[\]^_`{|}~])*$/,
  notOnlySpaceNumberSpecialCharsAndNotInjectionChars: /^(?!.*https?:\/\/|.*www\.|.*\.com)(?![ ¡!¿?"#%&'()*+,-./:<=>[\]^`{|}~\d\s]*$)[^\u25CF<>{}[\]=*;$@_]+$/i,
  notOnlySpaceSpecialCharsAndNotInjectionChars: /^(?!.*https?:\/\/|.*www\.|.*\.com)(?![ ¡!¿?"#%&'()*+,-./:<=>[\]^`{|}~\s]*$)[^\u25CF<>{}[\]=*;$@_]+$/i,
  notNumbersAndnotOnlySpaceNumberSpecialCharsAndNotInjectionChars: /^(?!.*https?:\/\/|.*www\.|.*\.com)(?![ ¡!¿?"#%&'()*+,-./:<=>[\]^`{|}~\s]*$)[^\d\u25CF<>{}[\]=*;$@_]+$/i,
  addressLineWithNoNumber: /^(?!.*https?:\/\/|.*www\.|.*\.com)(?![ ¡!¿?“#%&‘()*+,-./:<=>[\]^`{|}~\s]*$)[^\u25CF<>{}[\]=*;$@_\d\u0400-\u04FF\u0500-\u052F]+$/i,
  addressLineWithApartmentNumber: /^(?!.*https?:\/\/|.*www\.|.*\.com)(?![ ¡!¿?“#%&‘()*+,-./:<=>[\]^`{|}~\d\s]*$)[^\u25CF<>{}[\]=*;$@_\u0400-\u04FF\u0500-\u052F]+ (\d+|\d+[a-z]|\d+[a-z]?\/?\.?\d+[a-z]?|\d+-\d+|\d+\/[a-z]|[a-z]+\.\d+[a-z]?)$/i,
  notInjectionChars: /^(?!.*https?:\/\/|.*www\.|.*\.com)[^\u25CF<>{}[\]=*;]*$/,
  onlyNumbers: /^\d*$/,
  dateFormat: {
    'DD-MM-YYYY': /^\d{2}-\d{2}-\d{4}$/,
    'DD.MM.YYYY': /^\d{2}\.\d{2}\.\d{4}$/,
    'YYYY.MM.DD': /^\d{4}\.\d{2}\.\d{2}$/,
    'YYYY.MM.DD.': /^\d{4}\.\d{2}\.\d{2}\.$/,
    'MM-DD-YYYY': /^\d{2}-\d{2}-\d{4}$/,
    'YYYY-MM-DD': /^\d{4}-\d{2}-\d{2}$/,
    YYYYMMDD: /^\d{8}$/,
    YYYYMMDDXXXX: /^\d{12}$/,
    'MM-YYYY': /^\d{2}-\d{4}$/,
    'MM.YYYY': /^\d{2}.\d{4}$/,
    'YYYY-MM': /^\d{4}-\d{2}$/,
    'YYYY.MM.': /^\d{4}.\d{2}.$/
  },
  // Date regex for format DD-MM-YYYY
  date: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
  email: /^(?!^.{101})(?!.*?\.@)(?!.*?\.\.)^(?![.])[A-Za-z0-9!#$%.&'+?^_`~-]{1,64}@(?!-)(?!.*?\.\.-)(?:[A-Za-z0-9-]{0,63}[^-\s]\.(?!-))+[A-Za-z]{2,}$/
};
