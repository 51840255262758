import React from 'react';
import PropTypes from 'prop-types';
import { Text, Link } from 'Common';
import { t } from 'Utils/localization/i18next';
import styles from './TextWithLink.scss';

export const TextWithLink = ({
  labelBeforeLink,
  labelAfterLink,
  labelLink,
  link,
  linkTarget,
  lineBreak,
  labelBetweenLinks,
  linkLabels,
  links,
  name,
  ...rest
}) => {
  const labelBetweenLinksExist = !!labelBetweenLinks;
  const LabelWithLink = () => {
    return (
      <Text {...rest}>
        {labelBeforeLink}
        {lineBreak && <br />}
        {link && (
          <Link underline url={link} target={linkTarget}>
            {t(labelLink)}
          </Link>
        )}
        {labelAfterLink}
      </Text>
    );
  };
  const LabelWithLinks = () => {
    const ClassNameFunction = index => {
      if (index === 1) {
        return 'first-text-style';
      }
      if (index === 3) {
        return 'second-text-style';
      }
      return '';
    };
    const TextSpaceFunction = index => {
      if (index < 4) return ' ';
      return '';
    };
    return (
      <Text {...rest}>
        {labelBeforeLink}{' '}
        {labelBetweenLinks.map((labelBetweenLink, index) => {
          const labelInsideLink = linkLabels[index];
          const linkInsideLink = links[index];
          return name === 'allowSMS' ? (
            <span key={index}>
              {lineBreak && <br />}
              {t(labelBetweenLink)}{' '}
              <Link url={t(linkInsideLink)} target="_blank">
                {t(labelInsideLink)}.
              </Link>{' '}
            </span>
          ) : (
            <span key={index} className={styles[ClassNameFunction(index)]}>
              {lineBreak && <br />}
              {t(labelBetweenLink)}
              {TextSpaceFunction(index)}
            </span>
          );
        })}
        {labelAfterLink}
      </Text>
    );
  };
  return labelBetweenLinksExist ? <LabelWithLinks /> : <LabelWithLink />;
};

TextWithLink.propTypes = {
  id: PropTypes.string,
  labelBeforeLink: PropTypes.string,
  name: PropTypes.string,
  labelBetweenLinks: PropTypes.arrayOf(PropTypes.string),
  linkLabels: PropTypes.arrayOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.string),
  labelAfterLink: PropTypes.string,
  labelLink: PropTypes.string,
  link: PropTypes.string,
  to: PropTypes.string,
  linkTarget: PropTypes.string,
  lineBreak: PropTypes.bool,
  // Props passed down to Text component
  inline: PropTypes.bool,
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  center: PropTypes.bool,
  smaller: PropTypes.bool,
  margin: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  marginTop: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  marginBottom: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  marginLeft: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  marginRight: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  colour: PropTypes.oneOf([
    'grey-500',
    'grey-700',
    'brand-blue',
    'feedback-error'
  ]),
  className: PropTypes.string,
  style: PropTypes.object
};
